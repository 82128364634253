import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { ClienteDTO } from '../../backend/interface/ClienteDTO';
import { Delete_EliminaCliente, Post_CercaClienti } from '../../backend/services/ClientiService';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import Swal from 'sweetalert2';

export const ListClienti = () => {

  const [clienti, setClienti] = useState<ClienteDTO[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadFunction();
  }, []);

  const loadFunction = async () => {
    var response = await Post_CercaClienti({ ragioneSociale: undefined }, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setClienti(response?.data);
  }

  const Delete = async (id: number) => {
    Swal.fire({
      title: "Vuoi eliminare questo cliente?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`
    }).then((result) => {
      if (result.isConfirmed) {
        DoDelete(id);
      }
    });
  }

  const DoDelete = async (id: number) => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });    
    var response = await Delete_EliminaCliente(id, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    if (response.esito) {
      Swal.fire({
        title: "Operazione completata!",
        icon: "success"
      });
      loadFunction();
    } else {
      Swal.fire({
        title: "Operazione interrotta!",
        text: response.messaggio,
        icon: "error"
      });
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ul className="breadcrumb">
            <li>Clienti</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='boxWhite'>
            <Row>
              <Col>
                <h5>Elenco clienti</h5>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button color='primary' onClick={() => { navigate('/clienti/0'); }}>
                  <FaPlus /> Nuovo
                </Button>
              </Col>
            </Row>
            <br />
            <DataGrid dataSource={clienti} keyExpr="id" showBorders={true}>
              <FilterRow visible={true} applyFilter='auto' />
              <HeaderFilter visible={true} />
              <Paging enabled={false} />
              <Column dataField="id" caption='#' dataType="number" width={80} />
              <Column dataField="ragioneSociale" caption='Ragione sociale' dataType="string" />
              <Column caption='' width={120} cellRender={(e: any) => {
                return (
                  <React.Fragment>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: '8px', textAlign: 'right' }}>
                            <Link to={'/clienti/' + e.key} style={{ color: 'orange' }} title='Modifica'><FaEdit size={16} /></Link>
                          </td>
                          <td style={{ paddingLeft: '8px' }}>
                            <a href='#' style={{ color: 'red' }} title='Elimina' onClick={() => { Delete(e.key); }}><FaTrashAlt size={16} /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                );
              }}
              />
            </DataGrid>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

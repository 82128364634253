import React, { useEffect, useState } from 'react'
import { MailLogDTO } from '../backend/interface/MailLogDTO';
import { Post_CercaMail } from '../backend/services/MailLogService';
import { Button, Col, Input, Row } from 'reactstrap';
import { DataGrid, DateBox } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, LoadPanel, MasterDetail, Pager, Paging } from 'devextreme-react/data-grid';
import { FaSync } from 'react-icons/fa';

export const Dashboard = () => {

  const [mailLog, setMailLog] = useState<MailLogDTO[]>([]);

  useEffect(() => {
    loadFunction();
  }, []);

  const loadFunction = async (dateFilter?: Date) => {
    var response = await Post_CercaMail({ dataCreazioneUTC: dateFilter }, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setMailLog(response?.data);
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ul className="breadcrumb">
            <li>Dashboard</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='boxWhite'>
            <Row>
              <Col>
                <h5>Log mail</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Cerca indietro fino al</label>
                <DateBox
                  defaultValue={new Date()}
                  type="date"
                  displayFormat='dd/MM/yyyy'
                  onValueChanged={(e:any) => { loadFunction(new Date(e.value)); }}
                />
              </Col>        
            </Row>
            <br />
            <DataGrid dataSource={mailLog} keyExpr="id" showBorders={true}>
              <FilterRow visible={true} applyFilter='auto' />
              <HeaderFilter visible={true} />
              <Paging enabled={false} />
              <Column dataField="id" caption='#' dataType="number" width={80} />
              <Column dataField="dataCreazioneUTC" caption='Data creazione' dataType="datetime" format='dd/MM/yyyy HH:mm' />
              <Column dataField="oggetto" caption='Oggetto' dataType="string" />
              <Column dataField="mittente" caption='Mittente' dataType="string" />
              <Column dataField="destinatariA" caption='Destinatari' dataType="string" cellRender={(e: any) => {
                return (
                  <React.Fragment>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {e.data.destinatariA?.replace(";", "\n")}
                      <br />
                      {e.data.destinatariCC?.replace(";", "\n")}
                      <br />
                      {e.data.destinatariCCN?.replace(";", "\n")}
                    </div>
                  </React.Fragment>
                );
              }} />
              <Column dataField="flgInviato" caption='Inviato' dataType="boolean" width={100} />
              <Column dataField="flgErrore" caption='Errore' dataType="boolean" width={100} />
              <MasterDetail enabled={true} render={(e: any) => {
                return (
                  <React.Fragment>
                    Messaggio di errore:
                    <br />
                    <b>{e.data.errore}</b>
                  </React.Fragment>
                );
              }} />
              <Paging defaultPageSize={15} />
              <Pager
                visible={true}
                allowedPageSizes={[15, 30, 50, 200, 'all']}
                displayMode='compact'
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
              <LoadPanel enabled />
            </DataGrid>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

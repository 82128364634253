import axios from "axios";
import { CercaMailLogDTO } from "../interface/CercaMailLogDTO";

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_CercaMail = async (CercaMailLogDTO: CercaMailLogDTO, token: string) => {
  console.log(CercaMailLogDTO);
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/mailLog/cerca',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(CercaMailLogDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}
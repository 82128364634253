import React, { useEffect, useState } from 'react'
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { SmtpClienteDTO } from '../../backend/interface/SmtpClienteDTO';
import { Delete_EliminaSMTP, Post_CercaSMTP } from '../../backend/services/SmtpService';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

interface props {
  idCliente: number
}

export const ListSmtp = (props: props) => {

  const navigate = useNavigate();
  const [smtp, setSmtp] = useState<SmtpClienteDTO[]>([]);

  useEffect(() => {
    if (props.idCliente > 0)
      loadFunction();
  }, []);

  const loadFunction = async () => {
    var response = await Post_CercaSMTP({ nome: undefined, idCliente: props.idCliente }, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setSmtp(response?.data);
  }

  const DeleteSMTP = async (id: number) => {
    Swal.fire({
      title: "Vuoi eliminare questo SMTP?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`
    }).then((result) => {
      if (result.isConfirmed) {
        DoDelete(id);
      }
    });
  }

  const DoDelete = async (id: number) => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });    
    var response = await Delete_EliminaSMTP(id, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    if (response.esito) {
      Swal.fire({
        title: "Operazione completata!",
        icon: "success"
      });
      loadFunction();
    } else {
      Swal.fire({
        title: "Operazione interrotta!",
        text: response.messaggio,
        icon: "error"
      });
    }
  }  

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className='boxWhite'>
            <Row>
              <Col>
                <h5>Elenco SMTP cliente</h5>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Button color='primary' onClick={() => { navigate('/clienti/' + props.idCliente + '/smtp/0'); }}>
                  <FaPlus /> Nuovo
                </Button>
              </Col>
            </Row>            
            <br />
            <DataGrid dataSource={smtp} keyExpr="id" showBorders={true}>
              <FilterRow visible={true} applyFilter='auto' />
              <HeaderFilter visible={true} />
              <Paging enabled={false} />
              <Column dataField="id" caption='#' dataType="number" width={80} />
              <Column dataField="nome" caption='Nome' dataType="string" />
              <Column dataField="descrizione" caption='Descrizione' dataType="string" />
              <Column caption='' width={120} cellRender={(e: any) => {
                return (
                  <React.Fragment>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ paddingRight: '8px', textAlign: 'right' }}>
                            <Link to={'/clienti/' + e.data.cliente.id + '/smtp/' + e.key} style={{ color: 'orange' }} title='Modifica'><FaEdit size={16} /></Link>
                          </td>
                          <td style={{ paddingLeft: '8px' }}>
                            <a href='#' style={{ color: 'red' }} title='Elimina' onClick={() => { DeleteSMTP(e.key); }}><FaTrashAlt size={16} /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                );
              }}
              />
            </DataGrid>
          </div>
        </Col>
      </Row>

    </React.Fragment>
  )
}

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Login } from './pages/auth/Login';
import { Header } from './pages/layouts/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ListClienti } from './pages/clienti/ListClienti';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
import { Container } from 'reactstrap';
import { EditCliente } from './pages/clienti/EditCliente';
import { EditSmtp } from './pages/smtp/EditSmtp';
import { EditApp } from './pages/applicazioni/EditApp';
import { Dashboard } from './pages/Dashboard';
import { FullListSmtp } from './pages/smtp/FullListSmtp';
import { FullListApp } from './pages/applicazioni/FullListApp';

function App() {

  const [smtpRelayToken, setSmtpRelayToken] = useState("");

  useEffect(() => {
    setLocalStorageToken();
  }, [smtpRelayToken])

  const setLocalStorageToken = async () => {
    await setSmtpRelayToken(localStorage.getItem('BS_SmtpRelayToken') ?? "");
    if ((localStorage.getItem('BS_SmtpRelayToken') ?? "") != "") {
      require('./dist/css/App.css');
    } else {
      require('./dist/css/Login.css');
    }
    SetTimerKickOut();
  }

  const SetTimerKickOut = async () => {
    var interval = window.setInterval(async function () {
      if ((localStorage.getItem('BS_SmtpRelayToken') ?? "") != "") {
        var decodedToken = await parseJwt((localStorage.getItem('BS_SmtpRelayToken') ?? ""));
        if (Date.now() >= decodedToken.exp * 1000) {
          Swal.fire({
            title: "Sessione scaduta!",
            text: 'Effettuare nuovamente il login.',
            icon: "warning"
          });
          localStorage.removeItem("BS_SmtpRelayToken");
          setSmtpRelayToken("");
        }
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  const parseJwt = async (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  if (smtpRelayToken == "") {
    return (
      <React.Fragment>
        <Login />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Header setSmtpRelayToken={setSmtpRelayToken} />
          <Container style={{marginTop:'80px'}}>
            <Routes>
              <Route path='/' element={<Dashboard />} />
              <Route path='/clienti' element={<ListClienti />} />
              <Route path='/clienti/:clienteId' element={<EditCliente />} />
              <Route path='/clienti/:clienteId/smtp/:smtpId' element={<EditSmtp />} />
              <Route path='/clienti/:clienteId/smtp/:smtpId/app/:appId' element={<EditApp />} />
              <Route path='/smtp' element={<FullListSmtp />} />
              <Route path='/app' element={<FullListApp />} />
            </Routes>
            <br />
          </Container>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;

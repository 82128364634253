import { LoginRequestDTO } from "../interface/LoginRequestDTO";
import axios from "axios"

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_Login = async (LoginRequestDTO: LoginRequestDTO) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: endPoint + 'v1/auth/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(LoginRequestDTO)
    };

    return await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });

}
import React, { useState } from 'react'
import { FaRegPaperPlane } from 'react-icons/fa';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Post_InviaMail } from '../../backend/services/RelayService';
import { InviaMailDTO } from '../../backend/interface/InviaMailDTO';
import Swal from 'sweetalert2';

interface props {
  apiKey: string
}

export const SendMailTest = (props: props) => {

  const [inviaMail, setInviaMail] = useState<InviaMailDTO>({
    oggetto: 'Test invio mail',
    mittente: 'Smtp Relay'
  });  

  const TestSend = async () => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    
    var response = await Post_InviaMail(inviaMail, props.apiKey);
    if (response.esito) {
      Swal.fire({
        title: "Operazione completata!",
        icon: "success"
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Operazione interrotta!",
        text: response.messaggio,
        icon: "error"
      });
    }    
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className='boxWhite'>
          <h5>Test invio mail</h5>
            <br />            
            <Form>
              <FormGroup row>
                <Label for="txtFrom" sm={2}>
                  From
                </Label>
                <Col sm={6}>
                  <Input id="txtFrom" name="mailMittente" type="email" value={inviaMail.mailMittente}
                    onChange={(e: any) => { setInviaMail({ ...inviaMail, mailMittente: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtTo" sm={2}>
                  To
                </Label>
                <Col sm={6}>
                  <Input id="txtTo" name="mailA" type="email" value={inviaMail.mailA}
                    onChange={(e: any) => { setInviaMail({ ...inviaMail, mailA: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtCC" sm={2}>
                  CC
                </Label>
                <Col sm={6}>
                  <Input id="txtCC" name="mailCc" type="email" value={inviaMail.mailCc}
                    onChange={(e: any) => { setInviaMail({ ...inviaMail, mailCc: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtCCN" sm={2}>
                  CCN
                </Label>
                <Col sm={6}>
                  <Input id="txtCCN" name="MailCcn" type="text" value={inviaMail.MailCcn}
                    onChange={(e: any) => { setInviaMail({ ...inviaMail, mittente: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup check row>
                <Col sm={{ offset: 2, size: 10 }}>
                  <Button color='success' onClick={() => { TestSend(); }}>
                    <FaRegPaperPlane /> Invia
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

import axios from "axios";
import { CercaClienteDTO } from "../interface/CercaClienteDTO";
import { ClienteDTO } from "../interface/ClienteDTO";

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_CercaClienti = async (CercaClienteDTO: CercaClienteDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/clienti/cerca',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(CercaClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export const Get_LeggiCliente = async (id: number, token: string) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/clienti/leggi/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Post_CreaCliente = async (ClienteDTO: ClienteDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/clienti/crea',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(ClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Put_AggiornaCliente = async (ClienteDTO: ClienteDTO, token: string) => {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/clienti/aggiorna',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(ClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Delete_EliminaCliente = async (id: number, token: string) => {
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/clienti/elimina/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}
import React from 'react'
import { Link } from 'react-router-dom'
import { FaRegChartBar, FaUserTie  } from "react-icons/fa6";
import { FaMailBulk, FaSignOutAlt, FaThLarge } from "react-icons/fa";

interface props {
    setSmtpRelayToken: Function
}

export const Header = (props: props) => {

    const Logout = async () => {
        localStorage.removeItem("BS_SmtpRelayToken");
        props.setSmtpRelayToken("");
    }

    return (
        <React.Fragment>
            <ul className='topNavBar'>
                <li><Link to='/'><FaRegChartBar /> Dashboard</Link></li>
                <li><Link to='/clienti'><FaUserTie  /> Clienti</Link></li>
                <li><Link to='/smtp'><FaMailBulk  /> Smtp</Link></li>
                <li><Link to='/app'><FaThLarge  /> Applicazioni</Link></li>
                <li style={{ float: 'right' }}><a href="#" onClick={() => { Logout(); }}><FaSignOutAlt /> Logout</a></li>
            </ul>
        </React.Fragment>
    )
}

import axios from "axios";
import { CercaApplicazioneDTO } from "../interface/CercaApplicazioneDTO";
import { ApplicazioneDTO } from "../interface/ApplicazioneDTO";

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_CercaApp = async (CercaApplicazioneDTO: CercaApplicazioneDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/applicazioni/cerca',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(CercaApplicazioneDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export const Get_LeggiApp = async (id: number, token: string) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/applicazioni/leggi/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Post_CreaApp = async (ApplicazioneDTO: ApplicazioneDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/applicazioni/crea',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(ApplicazioneDTO)
  };

  return await axios.request(config)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Put_AggiornaApp = async (ApplicazioneDTO: ApplicazioneDTO, token: string) => {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/applicazioni/aggiorna',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(ApplicazioneDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Delete_EliminaApp = async (id: number, token: string) => {
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/applicazioni/elimina/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}
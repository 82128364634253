import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ApplicazioneDTO } from '../../backend/interface/ApplicazioneDTO';
import { Delete_EliminaApp, Post_CercaApp } from '../../backend/services/ApplicazioniService';
import Swal from 'sweetalert2';
import { Button, Col, Row } from 'reactstrap';
import { FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Paging } from 'devextreme-react/data-grid';

interface props {
    idCliente: number,
    idSmtp: number
  }

export const ListApp = (props: props) => {
    const navigate = useNavigate();
    const [app, setApp] = useState<ApplicazioneDTO[]>([]);
  
    useEffect(() => {
      if (props.idSmtp > 0)
        loadFunction();
    }, []);
  
    const loadFunction = async () => {
      var response = await Post_CercaApp({ nome: undefined, apiKey: undefined, smtpId: props.idSmtp }, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      setApp(response?.data);
    }
  
    const DeleteApp = async (id: number) => {
      Swal.fire({
        title: "Vuoi eliminare questa Applicazione?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Si",
        denyButtonText: `No`
      }).then((result) => {
        if (result.isConfirmed) {
          DoDelete(id);
        }
      });
    }
  
    const DoDelete = async (id: number) => {
      Swal.fire({
        title: 'Operazione in corso...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });      
      var response = await Delete_EliminaApp(id, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
        loadFunction();
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    }  
  
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className='boxWhite'>
              <Row>
                <Col>
                  <h5>Elenco applicazioni SMTP cliente</h5>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button color='primary' onClick={() => { navigate('/clienti/' + props.idCliente + '/smtp/' + props.idSmtp + '/app/0'); }}>
                    <FaPlus /> Nuovo
                  </Button>
                </Col>
              </Row>            
              <br />
              <DataGrid dataSource={app} keyExpr="id" showBorders={true}>
                <FilterRow visible={true} applyFilter='auto' />
                <HeaderFilter visible={true} />
                <Paging enabled={false} />
                <Column dataField="id" caption='#' dataType="number" width={80} />
                <Column dataField="nome" caption='Nome' dataType="string" />
                <Column dataField="macchina" caption='Macchina' dataType="string" />
                <Column dataField="descrizione" caption='Descrizione' dataType="string" />
                <Column caption='' width={120} cellRender={(e: any) => {
                  return (
                    <React.Fragment>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: '8px', textAlign: 'right' }}>
                              <Link to={'/clienti/' + props.idCliente + '/smtp/' + props.idSmtp + '/app/' + e.key} style={{ color: 'orange' }} title='Modifica'><FaEdit size={16} /></Link>
                            </td>
                            <td style={{ paddingLeft: '8px' }}>
                              <a href='#' style={{ color: 'red' }} title='Elimina' onClick={() => { DeleteApp(e.key); }}><FaTrashAlt size={16} /></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </React.Fragment>
                  );
                }}
                />
              </DataGrid>
            </div>
          </Col>
        </Row>
  
      </React.Fragment>
    )
}

import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SmtpClienteDTO } from '../../backend/interface/SmtpClienteDTO';
import { Get_LeggiSMTP, Post_CreaSMTP, Put_AggiornaSMTP } from '../../backend/services/SmtpService';
import { FaSave } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { ListApp } from '../applicazioni/ListApp';
import { Get_LeggiCliente } from '../../backend/services/ClientiService';

export const EditSmtp = () => {

  let { clienteId, smtpId } = useParams();
  const [smtp, setSmtp] = useState<SmtpClienteDTO>({
    id: 0,
    cliente: {
      id: parseInt(clienteId ?? "0") ?? 0
    }
  });

  useEffect(() => {
    if (parseInt(smtpId ?? "0") > 0) {
      loadFunction();
    } else {
      GetDatiCliente();
    }
  }, []);

  const loadFunction = async () => {
    var response = await Get_LeggiSMTP(parseInt(smtpId ?? "0"), (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setSmtp(response?.data);
  }

  const GetDatiCliente = async () => {
    var response = await Get_LeggiCliente(parseInt(clienteId ?? "0"), (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setSmtp({ ...smtp, cliente: { id: parseInt(clienteId ?? "0"), ragioneSociale: response.data.ragioneSociale } }); 
  }

  const SaveSMTP = async () => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });    
    if (parseInt(smtpId ?? "0") > 0) {
      var response = await Put_AggiornaSMTP(smtp, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    } else {
      var response = await Post_CreaSMTP(smtp, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
        setSmtp(response.data);
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    }    
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ul className="breadcrumb">
            <li><Link to='/clienti'>Clienti</Link></li>
            <li><Link to={'/clienti/' + smtp.cliente?.id}>{smtp.cliente?.ragioneSociale}</Link></li>
            <li>{(smtp.id > 0) ? smtp.nome : "Nuovo SMTP"}</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='boxWhite'>
            <h5>{(smtp.id > 0) ? "Modifica SMTP cliente" : "Creazione nuovo SMTP cliente"}</h5>
            <br />
            <Form>
              <FormGroup row>
                <Label for="txtId" sm={2}>
                  Id
                </Label>
                <Col sm={1}>
                  <Input id="txtId" name="id" type="text" disabled={true} value={smtp?.id} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtNome" sm={2}>
                  Nome
                </Label>
                <Col sm={6}>
                  <Input id="txtNome" name="nome" type="text" value={smtp?.nome}
                    onChange={(e: any) => { setSmtp({ ...smtp, nome: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtDescr" sm={2}>
                  Descrizione
                </Label>
                <Col sm={6}>
                  <Input id="txtDescr" name="descrizione" type="textarea" value={smtp?.descrizione} height={40}
                    onChange={(e: any) => { setSmtp({ ...smtp, descrizione: e.target.value }); }} />
                </Col>
              </FormGroup>              
              <FormGroup row>
                <Label for="txtHost" sm={2}>
                  Host/Porta
                </Label>
                <Col sm={4}>
                  <Input id="txtHost" name="host" type="text" value={smtp?.host}
                    onChange={(e: any) => { setSmtp({ ...smtp, host: e.target.value }); }} />
                </Col>
                <Col sm={2}>
                  <Input id="txtPort" name="porta" type="text" value={smtp?.porta}
                    onChange={(e: any) => { setSmtp({ ...smtp, porta: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="chkFlgAuth" sm={2} />
                <Col sm={{ size: 10 }}>
                  <FormGroup check>
                    <Input id="chkFlgAuth" type="checkbox" checked={smtp.flgAuth} 
                      onChange={(e: any) => { setSmtp({ ...smtp, flgAuth: e.target.checked }); }} />
                    {' '}
                    <Label check>
                      Autenticazione
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtUsername" sm={2}>
                  Username
                </Label>
                <Col sm={6}>
                  <Input id="txtUsername" name="username" type="text" value={smtp?.username} disabled={!smtp.flgAuth}
                    onChange={(e: any) => { setSmtp({ ...smtp, username: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtPassword" sm={2}>
                  Password
                </Label>
                <Col sm={6}>
                  <Input id="txtPassword" name="password" type="password" value={smtp?.password} disabled={!smtp.flgAuth}
                    onChange={(e: any) => { setSmtp({ ...smtp, password: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="chkFlgSSL" sm={2} />
                <Col sm={{ size: 10 }}>
                  <FormGroup check>
                    <Input id="chkFlgSSL" type="checkbox" checked={smtp.flgSSL} 
                      onChange={(e: any) => { setSmtp({ ...smtp, flgSSL: e.target.checked }); }} />
                    {' '}
                    <Label check>
                      SSL
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>                                          
              <FormGroup check row>
                <Col sm={{ offset: 2, size: 10 }}>
                  <Button color='success' onClick={() => { SaveSMTP(); }}>
                    <FaSave /> Salva
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <br />
        </Col>
      </Row>
      <div style={{ display: (parseInt(smtpId ?? "0") > 0) ? '' : 'none' }}>
        <ListApp idCliente={parseInt(clienteId ?? "0")} idSmtp={parseInt(smtpId ?? "0")} />
      </div>      
    </React.Fragment>
  )
}

import React, { useState } from 'react'
import { LoginRequestDTO } from '../../backend/interface/LoginRequestDTO';
import { Post_Login } from '../../backend/services/AuthService';
import Swal from 'sweetalert2';

export const Login = () => {

    const [toggleLoginForm, setToggleLoginForm] = useState(true);
    const [loginRequestDTO, setLoginRequestDTO] = useState<LoginRequestDTO>({
        email: '',
        password: ''
    });


    const DoLogin = async () => {
        var result = await Post_Login(loginRequestDTO);
        if (result.esito) {
            localStorage.setItem('BS_SmtpRelayToken', result.data.token);
            window.location.reload()
        } else {
            Swal.fire({
                title: "Operazione interrotta!",
                text: result.messaggio,
                icon: "error"
            });
        }
    }

    return (
        <React.Fragment>
            <div className="login-page">
                <div className="form">
                    <div style={{ display: (toggleLoginForm) ? 'none' : 'block' }}>
                        <input type="email" placeholder="Email" />
                        <button>INVIA LINK DI RESET</button>
                        <p className="message"><a href="#" onClick={() => { setToggleLoginForm(!toggleLoginForm); }}>Torna al login</a></p>
                    </div>
                    <div style={{ display: (toggleLoginForm) ? 'block' : 'none' }}>
                        <input type="email" placeholder="Email" onChange={(e: any) => { setLoginRequestDTO({ ...loginRequestDTO, email: e.target.value }); }} />
                        <input type="password" placeholder="Password" onChange={(e: any) => { setLoginRequestDTO({ ...loginRequestDTO, password: e.target.value }); }} />
                        <button onClick={() => { DoLogin(); }}>ENTRA</button>
                        <p className="message">Non riesci ad accedere? <a href="#" onClick={() => { setToggleLoginForm(!toggleLoginForm); }}>Recupera la password</a></p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

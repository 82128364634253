import axios from "axios";
import { CercaSmtpClienteDTO } from "../interface/CercaSmtpClienteDTO";
import { SmtpClienteDTO } from "../interface/SmtpClienteDTO";

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_CercaSMTP = async (CercaSmtpClienteDTO: CercaSmtpClienteDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/smtpclienti/cerca',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(CercaSmtpClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export const Get_LeggiSMTP = async (id: number, token: string) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/smtpclienti/leggi/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Post_CreaSMTP = async (SmtpClienteDTO: SmtpClienteDTO, token: string) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/smtpclienti/crea',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(SmtpClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Put_AggiornaSMTP = async (SmtpClienteDTO: SmtpClienteDTO, token: string) => {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/smtpclienti/aggiorna',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: JSON.stringify(SmtpClienteDTO)
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}

export const Delete_EliminaSMTP = async (id: number, token: string) => {
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: endPoint + 'v1/admin/smtpclienti/elimina/' + id,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };

  return await axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

}
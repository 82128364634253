import React, { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ClienteDTO } from '../../backend/interface/ClienteDTO';
import { Get_LeggiCliente, Post_CreaCliente, Put_AggiornaCliente } from '../../backend/services/ClientiService';
import Swal from 'sweetalert2';
import { ListSmtp } from '../smtp/ListSmtp';

export const EditCliente = () => {

  let { clienteId } = useParams();
  const [cliente, setCliente] = useState<ClienteDTO>({
    id: 0
  });

  useEffect(() => {
    if (parseInt(clienteId ?? "0") > 0)
      loadFunction();
  }, []);

  const loadFunction = async () => {
    var response = await Get_LeggiCliente(parseInt(clienteId ?? "0"), (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setCliente(response?.data);
  }

  const SaveCliente = async () => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });    
    if (parseInt(clienteId ?? "0") > 0) {
      var response = await Put_AggiornaCliente(cliente, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    } else {
      var response = await Post_CreaCliente(cliente, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
        setCliente(response.data);
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ul className="breadcrumb">
            <li><Link to='/clienti'>Clienti</Link></li>
            <li>{(cliente.id > 0) ? cliente.ragioneSociale : "Nuovo cliente"}</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='boxWhite'>
            <h5>Gestione anagrafica cliente</h5>
            <br />
            <Form>
              <FormGroup row>
                <Label for="txtId" sm={2}>
                  Id
                </Label>
                <Col sm={1}>
                  <Input id="txtId" name="id" type="text" disabled={true} value={cliente?.id} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtRagioneSociale" sm={2}>
                  Ragione sociale
                </Label>
                <Col sm={6}>
                  <Input id="txtRagioneSociale" name="ragioneSociale" type="text" value={cliente?.ragioneSociale}
                    onChange={(e: any) => { setCliente({ ...cliente, ragioneSociale: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup check row>
                <Col sm={{ offset: 2, size: 10 }}>
                  <Button color='success' onClick={() => { SaveCliente(); }}>
                    <FaSave /> Salva
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <br />
        </Col>
      </Row>
      <div style={{ display: (parseInt(clienteId ?? "0") > 0) ? '' : 'none' }}>
        <ListSmtp idCliente={parseInt(clienteId ?? "0")} />
      </div>
    </React.Fragment>
  )
}

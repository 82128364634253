import React, { useEffect, useState } from 'react'
import { FaSave, FaSyncAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicazioneDTO } from '../../backend/interface/ApplicazioneDTO';
import { Get_LeggiApp, Post_CreaApp, Put_AggiornaApp } from '../../backend/services/ApplicazioniService';
import Swal from 'sweetalert2';
import { Get_LeggiSMTP } from '../../backend/services/SmtpService';
import { UUID } from 'crypto';
import { SendMailTest } from './SendMailTest';

export const EditApp = () => {

  let { clienteId, smtpId, appId } = useParams();
  const [app, setApp] = useState<ApplicazioneDTO>({
    id: 0,
    apiKey: '',
    smtpCliente: {
      id: parseInt(smtpId ?? "0") ?? 0,
      cliente: {
        id: parseInt(clienteId ?? "0") ?? 0,
      }
    }
  });

  useEffect(() => {
    if (parseInt(appId ?? "0") > 0) {
      loadFunction();
    } else {
      GetDatiSmtpCliente();
    }
  }, []);

  const loadFunction = async () => {
    var response = await Get_LeggiApp(parseInt(appId ?? "0"), (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setApp(response?.data);
  }
  
  const GetDatiSmtpCliente = async () => {
    var response = await Get_LeggiSMTP(parseInt(smtpId ?? "0"), (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
    setApp({ ...app, smtpCliente: 
      { 
        id: parseInt(smtpId ?? "0"), 
        nome: response.data.nome, 
        cliente: 
        { 
          id: parseInt(clienteId ?? "0"), 
          ragioneSociale: response.data.cliente.ragioneSociale
        }
      }
    }); 
  }    

  const SaveApp = async () => {
    Swal.fire({
      title: 'Operazione in corso...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    if (parseInt(appId ?? "0") > 0) {
      var response = await Put_AggiornaApp(app, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    } else {
      var response = await Post_CreaApp(app, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
      if (response.esito) {
        Swal.fire({
          title: "Operazione completata!",
          icon: "success"
        });
        setApp(response.data);
      } else {
        Swal.fire({
          title: "Operazione interrotta!",
          text: response.messaggio,
          icon: "error"
        });
      }
    }  
  }  

  const generate_uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
    function(c) {
       var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
       return uuid.toString(16);
    });
 }  

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ul className="breadcrumb">
            <li><Link to='/clienti'>Clienti</Link></li>
            <li><Link to={'/clienti/' + app.smtpCliente.cliente?.id}>{app.smtpCliente.cliente?.ragioneSociale}</Link></li>
            <li><Link to={'/clienti/' + app.smtpCliente.cliente?.id + '/smtp/' + app.smtpCliente.id}>{app.smtpCliente.nome}</Link></li>
            <li>{(app.id > 0) ? app.nome : "Nuova applicazione smtp cliente"}</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='boxWhite'>
            <h5>{(app.id > 0) ? "Modifica applicazione SMTP cliente" : "Creazione nuova applicazione SMTP cliente"}</h5>
            <br />
            <Form>
              <FormGroup row>
                <Label for="txtId" sm={2}>
                  Id
                </Label>
                <Col sm={1}>
                  <Input id="txtId" name="id" type="text" disabled={true} value={app?.id} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtNome" sm={2}>
                  Nome
                </Label>
                <Col sm={6}>
                  <Input id="txtNome" name="nome" type="text" value={app?.nome}
                    onChange={(e: any) => { setApp({ ...app, nome: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtDescr" sm={2}>
                  Descrizione
                </Label>
                <Col sm={6}>
                  <Input id="txtDescr" name="descrizione" type="textarea" value={app?.descrizione}
                    onChange={(e: any) => { setApp({ ...app, descrizione: e.target.value }); }} />
                </Col>
              </FormGroup>   
              <FormGroup row>
                <Label for="txtMacchina" sm={2}>
                  Macchina
                </Label>
                <Col sm={6}>
                  <Input id="txtMacchina" name="macchina" type="text" value={app?.macchina}
                    onChange={(e: any) => { setApp({ ...app, macchina: e.target.value }); }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="txtApiKey" sm={2}>
                  Api Key
                </Label>
                <Col sm={4}>
                  <Input id="txtApiKey" name="apiKey" type="text" value={app?.apiKey} disabled={true}
                    onChange={(e: any) => { setApp({ ...app, apiKey: e.target.value }); }} />
                </Col>
                <Col sm={2}>
                  <Button color='primary' onClick={() => { setApp({ ...app, apiKey: generate_uuidv4() }); }}>
                    <FaSyncAlt /> Rigenera GUID
                  </Button>
                </Col>
              </FormGroup>                                       
              <FormGroup check row>
                <Col sm={{ offset: 2, size: 10 }}>
                  <Button color='success' onClick={() => { SaveApp(); }}>
                    <FaSave /> Salva
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
          <br />
        </Col>
      </Row>
      { app?.id > 0 ? <SendMailTest apiKey={app?.apiKey} /> : <></> }
    </React.Fragment>
  )
}

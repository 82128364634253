import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { DataGrid } from 'devextreme-react';
import { Column, FilterRow, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { ApplicazioneDTO } from '../../backend/interface/ApplicazioneDTO';
import { Post_CercaApp } from '../../backend/services/ApplicazioniService';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Delete_EliminaSMTP } from '../../backend/services/SmtpService';

export const FullListApp = () => {

    const [app, setApp] = useState<ApplicazioneDTO[]>([]);

    useEffect(() => {
        loadFunction();
    }, []);

    const loadFunction = async () => {
        var response = await Post_CercaApp({ apiKey: undefined }, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
        setApp(response?.data);
    }

    const DeleteSMTP = async (id: number) => {
        Swal.fire({
            title: "Vuoi eliminare questo SMTP?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Si",
            denyButtonText: `No`
        }).then((result) => {
            if (result.isConfirmed) {
                DoDelete(id);
            }
        });
    }

    const DoDelete = async (id: number) => {
        Swal.fire({
            title: 'Operazione in corso...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });        
        var response = await Delete_EliminaSMTP(id, (localStorage.getItem('BS_SmtpRelayToken') ?? ""));
        if (response.esito) {
            Swal.fire({
                title: "Operazione completata!",
                icon: "success"
            });
            loadFunction();
        } else {
            Swal.fire({
                title: "Operazione interrotta!",
                text: response.messaggio,
                icon: "error"
            });
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <ul className="breadcrumb">
                        <li>Applicazioni</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='boxWhite'>
                        <Row>
                            <Col>
                                <h5>Elenco applicazioni</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                            </Col>
                        </Row>
                        <br />
                        <DataGrid dataSource={app} keyExpr="id" showBorders={true}>
                            <FilterRow visible={true} applyFilter='auto' />
                            <HeaderFilter visible={true} />
                            <Paging enabled={false} />
                            <Column dataField="id" caption='#' dataType="number" width={80} />
                            <Column dataField="nome" caption='Nome' dataType="string" />
                            <Column dataField="macchina" caption='Macchina' dataType="string" />
                            <Column dataField="smtpCliente.nome" caption='SMTP' dataType="string" />
                            <Column dataField="smtpCliente.cliente.ragioneSociale" caption='Cliente' dataType="string" />
                            <Column dataField="descrizione" caption='Descrizione' dataType="string" />
                            <Column caption='' width={120} cellRender={(e: any) => {
                                return (
                                    <React.Fragment>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ paddingRight: '8px', textAlign: 'right' }}>
                                                        <Link to={'/clienti/' + e.data.smtpCliente.cliente.id + '/smtp/' + e.data.smtpCliente.id + '/app/' + e.key} style={{ color: 'orange' }} title='Modifica'><FaEdit size={16} /></Link>
                                                    </td>
                                                    <td style={{ paddingLeft: '8px' }}>
                                                        <a href='#' style={{ color: 'red' }} title='Elimina' onClick={() => { DeleteSMTP(e.key); }}><FaTrashAlt size={16} /></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                );
                            }}
                            />
                        </DataGrid>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

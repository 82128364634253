import axios from "axios";
import { InviaMailDTO } from "../interface/InviaMailDTO";

const endPoint = process.env.REACT_APP_END_POINT_API;

export const Post_InviaMail = async (InviaMailDTO: InviaMailDTO, apiKey: string) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: endPoint + 'v1/relay/InviaMail',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': apiKey
        },
        data: JSON.stringify(InviaMailDTO)
      };
  
      return await axios.request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
}